html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin-top: 0;
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  color: #1d1d1d;
  letter-spacing: 0.5px;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  margin: 0 0 1em;
  padding: 0;
}

html,
body,
#root {
  height: 100%;
  min-height: 100%;
}

/*
   Override the behaviour of the MUI drawer background so it can be clicked through.
   This is needed since when the drawer opens, an invisible element is placed behind it,
   and sits in front of the canvas. We need to allow clicks through this, otherwise
   the canvas cannot be interacted. We apply auto to the direct children of these elements,
   otherwise click events won't work at all on the drawer components.
*/
.MuiDrawer-root {
  pointer-events: none;
}

.MuiDrawer-root * {
  pointer-events: auto;
}

.MuiDrawer-root .MuiBackdrop-invisible {
  pointer-events: none;
}

.MuiDrawer-root .MuiBackdrop-invisible * {
  pointer-events: auto;
}

code.debug {
  margin: 0.5rem;
  padding: 0.5rem;
  border: 4px solid orange;
  outline: 1px solid black;
  background-color: steelblue;
  font-family: monospace;
  font-weight: bold;
  color: white;
}

code.debug:before {
  display: block;
  content: 'DEVELOPER DEBUG';
  color: orange;
}
